import React from "react";

import type { Category } from "../../../../client";

interface MenuCategoryDividerProps {
  category: Category;
}

const MenuCategoryDivider: React.FC<MenuCategoryDividerProps> = ({
  category
}) => {
  return (
    <div className="menu-misc-divider">
      <h3 className="menu-misc-divider-title">{category.title}</h3>
    </div>
  );
};

export { MenuCategoryDivider };

import React from "react";

import { TopNav } from "./TopNav";
import { LeftNav } from "./LeftNav/LeftNav";
import { LinkToIndex } from "../Link";
import { RightNav } from "./RightNav/RightNav";
import { DataController } from "../DataController";

export function Header() {
  return (
    <div className="header">
      <DataController />
      <div className="global-width--header">
        <div className="header-padding header-main">
          <div className="header-container header-logo">
            <LeftNav />
            <LinkToIndex className="header-logo">
              <i className="icon icon--app" aria-label="Hem" />
            </LinkToIndex>
          </div>
          <div className="header-container header-menu">
            <TopNav />
          </div>
          <div className="header-container header-settings">
            <RightNav />
          </div>
        </div>
      </div>
      <div className="sponsorbanners visible-xs"></div>
    </div>
  );
}

import React from "react";
import Image from "next/image";

import type { MouseEvent } from "react";
import type { Link } from "../../../../config/ConfigType";

import { DataControllerFooter } from "../../../DataController";
import SchibstedLogo from "../../../../assets/svg/schibsted-logo.svg";
import { getConfig } from "../../../../config";
import { getImageSizes } from "../../../../helpers";
import { triggerPrivacyManager } from "../../../Ad/utils/ads/functions";

const sizes = getImageSizes({ web: "40px", tablet: "6vw", mobile: "10vw" });

type Links = (Link | undefined)[];

export function MenuLinks() {
  const {
    translation: {
      application_name,
      eic,
      menu: {
        leave_a_comment,
        my_page,
        privacy_policy,
        custom,
        customer_service,
        publisher,
        editor_blog
      }
    }
  } = getConfig();
  const links: Links = [
    leave_a_comment,
    customer_service,
    publisher,
    editor_blog
  ];

  const privacyLinks: Links = [privacy_policy, my_page];
  if (custom) {
    links.splice(custom.index, 0, ...custom.links);
  }

  return (
    <>
      <div className="menu-misc-links">
        <div className="menu-misc-header">
          <h3>Om {application_name}</h3>
        </div>
        {links.map((link, i) => link && <MenuLink key={i} link={link} />)}
        <p className="menu-misc--location">
          {eic?.location.name}, {eic?.location.address}
          {". "}
          {eic?.location.organisation_number}
        </p>
      </div>
      <div className="menu-misc-links">
        <div className="menu-misc-header">
          <h3>Din personliga data</h3>
        </div>
        {privacyLinks.map(
          link =>
            link && (
              <div key={link.title} className="menu-misc--privacy">
                <MenuLink link={link} />
                <div className="menu-misc--logo">
                  <SchibstedLogo />
                </div>
              </div>
            )
        )}
        <CookiePolicyLink />
        <div className="menu-misc-link--footer">
          <DataControllerFooter />
        </div>
      </div>
    </>
  );
}

function CookiePolicyLink() {
  const handleClick = async (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    triggerPrivacyManager();
  };

  const { NEXT_PUBLIC_PSI_APP_ID } = getConfig();

  if (NEXT_PUBLIC_PSI_APP_ID) {
    return null;
  }
  return (
    <div className="menu-misc--privacy">
      <button className="btn menu-misc-item" onClick={handleClick}>
        Inställningar för cookies
      </button>
      <div className="menu-misc--logo">
        <SchibstedLogo />
      </div>
    </div>
  );
}

function MenuLink({ link }: { link: Link }) {
  return (
    <a
      role="menuitem"
      href={link.url}
      className="menu-misc-item"
      rel="noopener noreferrer"
      target="_blank"
    >
      {link.icon ? (
        <Image
          className="menu--icon"
          alt="menu-icon"
          src={link.icon}
          sizes={sizes}
          width={0}
          height={0}
        />
      ) : (
        ""
      )}
      <span>{link.title}</span>
    </a>
  );
}

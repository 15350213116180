import React, { useCallback, useContext } from "react";

import { TrackingContext } from "../../../../../contexts/TrackingContext";
import SchibstedLogo from "../../../../../assets/svg/schibsted-logo.svg";
import MembershipNotPremiumIcon from "../../../../../assets/svg/membership-icon.svg";
import MembershipPremiumIcon from "../../../../../assets/svg/membership-premium-icon.svg";
import AccountIcon from "../../../../../assets/svg/account-icon.svg";
import LogoutIcon from "../../../../../assets/svg/logout.svg";
import { getConfig } from "../../../../../config";
import { logout } from "../../../../../containers/GlobalStateContainer/userState/actions/logout";
import { initialUserState } from "../../../../../containers/GlobalStateContainer/userState/initialState";
import { useGlobalStore } from "../../../../../containers/GlobalStateContainer/store";

export function LoggedMenuMembership() {
  const {
    subscriptionsInfo: { isPremium },
    setFullUserState
  } = useGlobalStore();
  const { setSalesPosterReferrerArticle } = useContext(TrackingContext);
  const { NEXT_PUBLIC_MER, NEXT_PUBLIC_NEWSROOM } = getConfig();
  const isOmnieko = NEXT_PUBLIC_NEWSROOM === "omnieko";
  const {
    translation: {
      menu: { get_premium }
    }
  } = getConfig();

  const onLogout = useCallback(() => {
    logout();

    setFullUserState(initialUserState);
  }, [setFullUserState]);

  const MembershipIcon = (
    <div className="menu-mega-settings-links--icon">
      {isPremium ? <MembershipNotPremiumIcon /> : <MembershipPremiumIcon />}
    </div>
  );

  return (
    <div className="menu-mega-settings menu-mega--middle">
      <div className="menu-mega-settings-links">
        {(NEXT_PUBLIC_MER || isOmnieko) && (
          <div
            className={`menu-mega-settings-links--container ${
              isOmnieko ? "primary" : ""
            }`}
          >
            {MembershipIcon}
            {isPremium ? (
              <a
                rel="noopener noreferrer"
                href="https://omni.se/minsida/medlemskap"
                target="_blank"
                className="menu-mega-settings-links-link"
              >
                Ditt medlemskap
              </a>
            ) : (
              <a
                rel="noopener noreferrer"
                href={get_premium.url}
                target="_blank"
                className="menu-mega-settings-links-link menu--premium"
                onClick={() => {
                  setSalesPosterReferrerArticle(undefined);
                }}
              >
                {get_premium.title}
              </a>
            )}
          </div>
        )}

        <div className="menu-mega-settings-links--container">
          <div className="menu-mega-settings-links--icon">
            <AccountIcon />
          </div>
          <a
            rel="noopener noreferrer"
            href="https://omni.se/minsida"
            target="_blank"
            className="menu-mega-settings-links-link"
          >
            Ditt konto
            <div className="menu-misc--logo">
              <SchibstedLogo />
            </div>
          </a>
        </div>
        <div className="menu-mega-settings-links--container">
          <div className="menu-mega-settings-links--icon">
            <LogoutIcon />
          </div>
          <button onClick={onLogout} className="menu-mega-settings-links-link">
            Logga ut
          </button>
        </div>
      </div>
    </div>
  );
}

import React from "react";

import SchibstedMediaLogo from "../../../../assets/svg/schibsted-media-logo.svg";
import { getConfig } from "../../../../config";

export const MenuFooter = () => {
  const {
    translation: { application_name }
  } = getConfig();
  return (
    <div className="menu-misc--footer">
      <p className="menu-misc-item">{application_name} är en del av</p>
      <div className="menu-misc--logo-media">
        <SchibstedMediaLogo />
      </div>
    </div>
  );
};

import React, { useContext } from "react";

import { Menu } from "./Menu/Menu";
import { PremiumLockContext } from "../../../containers/PremiumLockContainer";
import { useMenu } from "../../../hooks/useMenu";

const LeftNav = () => {
  const { lockScreen } = useContext(PremiumLockContext);

  const { currentPanel, openPanel, closePanel } = useMenu();

  const isOpen = !!currentPanel;

  const onBlur = (event: React.FocusEvent) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      closePanel();
    }
  };

  return (
    <div className="header-burger-container" onBlur={onBlur} tabIndex={-1}>
      <button
        onClick={() => {
          lockScreen(false);
          openPanel("main");
        }}
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-label="menu"
        className="header-burger"
      >
        <i className="icon icon--burger" />
      </button>
      {isOpen && <Menu closePanel={closePanel} />}
    </div>
  );
};

export { LeftNav };

import React from "react";

import type { ClosePanel } from "../../../../hooks/useMenu";

import { MenuCategoryDivider } from "./MenuCategoryDivider";
import { MenuCategoryLink } from "./MenuCategoryLink";
import { useGlobalStore } from "../../../../containers/GlobalStateContainer/store";

interface MenuCategoriesProps {
  closePanel: ClosePanel;
}

const MenuCategories: React.FC<MenuCategoriesProps> = ({ closePanel }) => {
  const { categories } = useGlobalStore();
  return (
    <div className="menu-misc-categories">
      {categories
        .filter(category => category.public)
        .map(category =>
          category.type === "Category" ? (
            <MenuCategoryLink
              key={category.category_id}
              category={category}
              closePanel={closePanel}
            />
          ) : (
            <MenuCategoryDivider key={category.title} category={category} />
          )
        )}
    </div>
  );
};

export { MenuCategories };

import React from "react";

import { getConfig } from "../../../../config";

export function Contact() {
  const {
    translation: { contact }
  } = getConfig();
  if (!contact) {
    return null;
  }
  return (
    <div>
      <div className="menu-misc-item menu-misc-item--contact">
        <strong>{contact.header}</strong>
        <br />
        <a
          role="menuitem"
          className="a a--primary"
          href={`mailto:${contact.email}`}
        >
          {contact.email}
        </a>
      </div>
    </div>
  );
}

import React from "react";

import type { CSSProperties } from "react";
import type { Panel, OpenPanel } from "../../../hooks/useMenu";

import { getConfig } from "../../../config";
import ActiveProfileSVG from "../../../assets/svg/profile.svg";
import DisabledProfileSVG from "../../../assets/svg/profile-disabled.svg";
import NewsmixSVG from "../../../assets/svg/newsmix.svg";

interface SVGComponentProps {
  currentPanel: Panel;
  isOpen: boolean;
}

export function LoggedNav({
  isOpen,
  currentPanel,
  openPanel
}: {
  isOpen: boolean;
  currentPanel: Panel;
  openPanel: OpenPanel;
}) {
  const config = getConfig();

  return (
    <>
      <button
        onClick={() => {
          openPanel("newsmix");
        }}
        aria-haspopup="true"
        aria-expanded={isOpen}
        className="topnav-button topnav-button--newsmix"
      >
        <div className="user-newsmix">
          <div className="user user-newsmix--btn">
            <NewsmixSvg isOpen={isOpen} currentPanel={currentPanel} />
            <div className="user-newsmix--text">Ändra nyhetsmix</div>
          </div>
        </div>
      </button>

      <button
        onClick={() => {
          openPanel("profile");
        }}
        aria-haspopup="true"
        aria-expanded={isOpen}
        className="topnav-button"
      >
        <div className="user-profile">
          <div className="user user-profile--btn">
            <ProfileSvg isOpen={isOpen} currentPanel={currentPanel} />
            <div className="user-profile--text">
              {config.translation.account_text}
            </div>
          </div>
        </div>
      </button>
    </>
  );
}

function ProfileSvg({ currentPanel, isOpen }: SVGComponentProps) {
  const isActive = currentPanel === "profile" && isOpen;

  return (
    <div
      className="user-profile-icon"
      style={
        {
          "--profile-icon": `var(--profile-icon--${
            isActive ? "active" : "disabled"
          })`
        } as CSSProperties
      }
    >
      {isActive ? <ActiveProfileSVG /> : <DisabledProfileSVG />}
    </div>
  );
}

function NewsmixSvg({ currentPanel, isOpen }: SVGComponentProps) {
  const isActive = currentPanel === "newsmix" && isOpen;

  return (
    <div
      className="user-profile-icon"
      style={
        {
          "--profile-icon": `var(--profile-icon--${
            isActive ? "active" : "disabled"
          })`
        } as CSSProperties
      }
    >
      <NewsmixSVG />
    </div>
  );
}

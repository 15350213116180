import React from "react";

import type { Category } from "../../../../client";
import type { ClosePanel } from "../../../../hooks/useMenu";

import { LinkToCategory } from "../../../Link";
import { MenuIcon } from "./MenuIcon";

interface MenuCategoryLinkProps {
  category: Category;
  closePanel: ClosePanel;
}

const MenuCategoryLink: React.FC<MenuCategoryLinkProps> = ({
  category,
  closePanel
}) => {
  return (
    <LinkToCategory
      category={category}
      className="menu-misc-category menu-misc-item"
      role="menuitem"
      onClick={closePanel}
    >
      <MenuIcon category={category} />
      <span>{category.title}</span>
    </LinkToCategory>
  );
};

export { MenuCategoryLink };

import React from "react";
import Image from "next/image";

import type { ReactElement } from "react";
import type { Category } from "../../../../client";

import { getImageSizes } from "../../../../helpers";

const sizes = getImageSizes({ web: "20px", tablet: "3vw", mobile: "5vw" });

interface Props {
  category?: Category;
}

export function MenuIcon({ category }: Props): ReactElement | null {
  if (!category) return null;

  const { icon, slug } = category;
  if (!icon?.image_url || !slug) return null;

  const { image_url } = icon;

  return (
    <div className="menu--icon">
      <Image src={image_url} alt={slug} sizes={sizes} width={0} height={0} />
    </div>
  );
}

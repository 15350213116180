import React from "react";

import type { ClosePanel } from "../../../../hooks/useMenu";

import { SearchForm } from "../../../SearchForm";
import { MenuCategories } from "./MenuCategories";
import { MenuLinks } from "./MenuLinks";
import { Contact } from "./Contact";
import { MenuFooter } from "./MenuFooter";

interface MenuProps {
  closePanel: ClosePanel;
}

const Menu: React.FC<MenuProps> = ({ closePanel }) => {
  return (
    <div className="menu menu-misc" role="menu">
      <div className="menu-misc-search hidden-md hidden-lg hidden-xl">
        <SearchForm />
      </div>
      <MenuCategories closePanel={closePanel} />
      <MenuLinks />
      <Contact />
      <MenuFooter />
    </div>
  );
};

export { Menu };
